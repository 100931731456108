import firebase from 'gatsby-plugin-firebase'
import { useEffect, useState, useContext } from 'react'
import { unionBy, equals } from 'lodash'
import { AuthContext, AuthContextProps } from '../components/User/AuthProvider'
import usePrevious from './usePrevious'

export interface IMessage {
  id: string
  content: string
	uid: string
	createdOn: Date
}

export interface HookOutput {
  allMessages: Array<IMessage>
}

const toDate = (firebaseTimestamp:FirebaseFirestore.Timestamp):Date => {
	const { seconds, nanoseconds } = firebaseTimestamp
	return new firebase.firestore.Timestamp(seconds, nanoseconds).toDate()
}

const useStickieMessages = (stickieId: string): HookOutput => {
	const [recentMessages, setRecentMessages] = useState<Array<IMessage>>(Array<IMessage>())
	const [allMessages, setAllMessages] = useState<Array<IMessage>>(Array<IMessage>())
	const [initialized] = useState<Date>(new Date())
	const previousRecentMessages = usePrevious(recentMessages)
	const { user }:Partial<AuthContextProps> = useContext(AuthContext)
	const { uid } = user || {}

	useEffect(() => {
		if (uid) {
			const ref = firebase.firestore()
				.collection('stickerStats')
				.doc(stickieId)
				.collection('messages')
				.limitToLast(10)
				.orderBy('createdOn', 'asc')
				.where('uid', '==', uid)

			const unsubscribe = ref.onSnapshot((snapshot) => {
				const newMessages = snapshot.docs.map((doc:firebase.firestore.QueryDocumentSnapshot<firebase.firestore.DocumentData>) => {
					const { content, createdOn } = doc.data()
					return {
						id: doc.id,
						content,
						uid,
						createdOn: toDate(createdOn),
					}
				})

				// console.log(newMessages)
				// console.log(`newMessages.length=${newMessages.length}`)

				setRecentMessages(newMessages)
			}, (err) => {
				console.error(err)
			})

			return (() => {
				unsubscribe()
			})
		}
		return (() => {})
	}, [stickieId, uid])

	useEffect(() => {
		if (!equals(previousRecentMessages, recentMessages)) {
			const relevantMessages = recentMessages.filter((message:IMessage) => message.createdOn >= initialized)
			const merged = unionBy(allMessages, relevantMessages, 'id')
			// console.log(`merged${JSON.stringify(merged)}`)
			setAllMessages(merged)
		}
	}, [allMessages, previousRecentMessages, recentMessages, initialized])

	return {
		allMessages,
	}
}

export default useStickieMessages
