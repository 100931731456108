import * as React from 'react'
import { IAction } from '../../../../hooks/useStickieActions'
import * as Styles from '../styles'

interface ComponentProps {
  // id: IAction['id']
	// label: IAction['label']
	action: IAction
	doAction: (action: IAction) => void
	disabled: boolean

}

const ActionButton:React.FC<ComponentProps> = (props) => {
	const { action, doAction, disabled } = props
	const { label } = action

	return (
		<Styles.ActionButton onClick={() => doAction(action)} disabled={disabled}>
			{label}
		</Styles.ActionButton>
	)
}

export default ActionButton
