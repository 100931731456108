import { motion } from 'framer-motion'
import React from 'react'
import styled from 'styled-components'

import { StickerEffect, StickerProperties } from '../common/shared/schema12'
import { device, desktopDemoDevice } from '../utils/device'

type StickerComponentProps = StickerProperties & StickerEffect & StickerAnimationProps

type ComponentProps = StickerComponentProps & {
	currentUrl: string
}

interface StickerPositioningContainerProps {
  left: string
  top: string
}
interface StickerResizingContainerProps {
  rotation: number
  scale: number
}

interface StickerAnimationProps {
	onFinished: Function
}

/*
interface StickerBackgroundProps {
	url?:string
	color?:string
}

const StickerBackgroundContainer = styled.div<StickerBackgroundProps>`
	position:absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	width: 100vw;
	height: 100vw;
	background-image: ${(props) => (props.url ? `url(${props.url})` : 'none')};
	background-color: ${(props) => (props.color ? `${props.color}` : 'transparent')};
	background-size: contain;
	@media ${device.isTabletOrLarger} {
		width: ${desktopDemoDevice.width}px;
		height: ${desktopDemoDevice.width}px;
	}
`
*/

const StickerPositioningContainer = styled(motion.div)<StickerPositioningContainerProps>`
	position:absolute;
	left: 0px;
	top: 0px;
	bottom: 0px;
	right: 0px;
	width: 100vw;
	height: 100vw;
	@media ${device.isTabletOrLarger} { 
		width: ${desktopDemoDevice.width}px;
		height: ${desktopDemoDevice.width}px;
	}
	/* border: 1px solid red; */
`

const StickerResizingContainer = styled(motion.div)<StickerResizingContainerProps>`
	position:absolute;
	/* border:1px dotted green; */
	left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
	transform: ${(props) => `rotate(${props.rotation}rad) scale(${props.scale})`};
`

const StickerImage = styled.img`
	width: 100%;
	height: 100%;
	object-fit: contain;
`
const whileTap = { rotate: -5, scale: 0.85 }

// const StickerComponent: React.FC<StickerComponentProps> = (props) => {
const StickerComponent: React.FC<ComponentProps> = (props) => {
	const {
		animation, scale, top, left, rotation, onFinished, currentUrl,
	} = props

	const { type } = animation

	const translateX = device.isTabletOrLarger ? ((parseFloat(left) * desktopDemoDevice.width) / 100) : `calc((${parseFloat(left)}*100vw)/100)`
	const translateY = device.isTabletOrLarger ? ((parseFloat(top) * desktopDemoDevice.width) / 100) : `calc((${parseFloat(top)}*100vw)/100))`

	const shakeXs = [-1, 2, -4, 4, -4, 4, -4, 2, -1, 0]
	const multiplyShake = (factor:number):Array<number> => shakeXs.map((x:number) => x * factor)
	// console.log(`props=${JSON.stringify(props)}`)
	const delay = animation?.delay || 0

	// console.log(`currentUrl=${currentUrl}`)
	switch (type) {
	case 'fadeIn':
		return (
			<StickerPositioningContainer
				left={left}
				top={top}
				initial={{
					opacity: 0,
					translateX,
					translateY,
				}}
				animate={{
					opacity: 1,
					translateX,
					translateY,
				}}
				transition={{
					delay,
					duration: animation?.duration || 0.4,
				}}
				whileTap={whileTap}
				onAnimationComplete={() => {
					onFinished()
				}}

			>
				<StickerResizingContainer
					scale={scale}
					rotation={rotation}
				>
					<StickerImage
						src={currentUrl}
						alt=""
					/>
				</StickerResizingContainer>
			</StickerPositioningContainer>
		)
	case 'fadeOut':
		return (
			<StickerPositioningContainer
				left={left}
				top={top}
				initial={{

					translateX,
					translateY,
				}}
				animate={{
					opacity: 0,
					translateX,
					translateY,
				}}
				transition={{
					delay,
					duration: animation?.duration || 0.3,
				}}
				whileTap={whileTap}
				onAnimationComplete={() => {
					onFinished()
				}}

			>
				<StickerResizingContainer
					scale={scale}
					rotation={rotation}
				>
					<StickerImage
						src={currentUrl}
						alt=""
					/>
				</StickerResizingContainer>
			</StickerPositioningContainer>
		)
	case 'spinIn':
		return (
			<StickerPositioningContainer
				left={left}
				top={top}
				initial={{
					scale: 0,
					rotate: 180,
					translateX,
					translateY,
				}}
				animate={{
					scale: 1,
					rotate: 0,
					translateX,
					translateY,
				}}
				transition={{
					type: 'spring',
					stiffness: 260,
					damping: 20,
					delay,
				}}
				whileTap={whileTap}
				onAnimationComplete={() => {
					onFinished()
				}}

			>
				<StickerResizingContainer
					scale={scale}
					rotation={rotation}
				>
					<StickerImage
						src={currentUrl}
						alt=""
					/>
				</StickerResizingContainer>
			</StickerPositioningContainer>
		)
	case 'spinOut':
		return (
			<StickerPositioningContainer
				left={left}
				top={top}
				initial={{
					scale: 1,
					rotate: 0,
					translateX,
					translateY,
				}}
				animate={{
					scale: 0,
					rotate: 1800,
					translateX,
					translateY,
				}}
				transition={{
					type: 'spring',
					stiffness: 260,
					damping: 20,
					delay,
				}}
				whileTap={whileTap}
				onAnimationComplete={() => {
					onFinished()
				}}

			>
				<StickerResizingContainer
					scale={scale}
					rotation={rotation}
				>
					<StickerImage
						src={currentUrl}
						alt=""
					/>
				</StickerResizingContainer>
			</StickerPositioningContainer>
		)

	case 'shake':
		return (
			<StickerPositioningContainer
				left={left}
				top={top}
				initial={{
					translateX,
					translateY,
				}}
				animate={{
					x: [null, ...multiplyShake(1)],
					rotate: 0,
					scale: 1,
				}}
				transition={{
					ease: [0.17, 0.67, 0.83, 0.67],
					duration: animation?.duration || 0.86,
					times: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
					delay,
				}}
				whileTap={whileTap}
				onAnimationComplete={() => {
					onFinished()
				}}
			>
				<StickerResizingContainer
					scale={scale}
					rotation={rotation}
				>
					<StickerImage
						src={currentUrl}
						alt=""
					/>
				</StickerResizingContainer>
			</StickerPositioningContainer>
		)
	case 'shakeMedium':
		return (
			<StickerPositioningContainer
				left={left}
				top={top}
				initial={{
					translateX,
					translateY,
				}}
				animate={{
					x: [null, ...multiplyShake(3)],
					rotate: 0,
					scale: 1,
				}}
				transition={{
					ease: [0.17, 0.67, 0.83, 0.67],
					duration: animation?.duration || 0.86,
					times: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0],
					delay,
				}}
				whileTap={whileTap}
				onAnimationComplete={() => {
					onFinished()
				}}
			>
				<StickerResizingContainer
					scale={scale}
					rotation={rotation}
				>
					<StickerImage
						src={currentUrl}
						alt=""
					/>
				</StickerResizingContainer>
			</StickerPositioningContainer>
		)
	case 'flyOffToRight':
		return (
			<StickerPositioningContainer
				left={left}
				top={top}
				initial={{
					translateX,
					translateY,
				}}
				animate={{
					translateX: '100%',
					rotate: 0,
					scale: 1,
				}}
				transition={{
					delay,
					duration: animation?.duration || 0.3,
				}}
				whileTap={whileTap}
				onAnimationComplete={() => {
					onFinished()
				}}
			>
				<StickerResizingContainer
					scale={scale}
					rotation={rotation}
				>
					<StickerImage
						src={currentUrl}
						alt=""
					/>
				</StickerResizingContainer>
			</StickerPositioningContainer>
		)
	case 'slideUp':
		return (
			<StickerPositioningContainer
				left={left}
				top={top}
				initial={{
					translateX,
					translateY: '100%',
				}}
				animate={{
					translateY,
				}}
				transition={{
					delay: animation?.delay || 0,
					duration: animation?.duration || 0.3,
				}}
				whileTap={whileTap}
				onAnimationComplete={() => {
					onFinished()
				}}
			>
				<StickerResizingContainer
					scale={scale}
					rotation={rotation}
				>
					<StickerImage
						src={currentUrl}
						alt=""
					/>
				</StickerResizingContainer>
			</StickerPositioningContainer>
		)
	case 'slideDown':
		return (
			<StickerPositioningContainer
				left={left}
				top={top}
				initial={{
					translateX,
					translateY,
				}}
				animate={{
					translateY: '100%',
				}}
				transition={{
					delay: animation?.delay || 0,
					duration: animation?.duration || 0.3,
				}}
				whileTap={whileTap}
				onAnimationComplete={() => {
					onFinished()
				}}
			>
				<StickerResizingContainer
					scale={scale}
					rotation={rotation}
				>
					<StickerImage
						src={currentUrl}
						alt=""
					/>
				</StickerResizingContainer>
			</StickerPositioningContainer>
		)
	case 'slideRight':
		return (
			<StickerPositioningContainer
				left={left}
				top={top}
				initial={{
					translateX: '-100%',
				}}
				animate={{
					translateX,
				}}
				transition={{
					delay: animation?.delay || 0,
					duration: animation?.duration || 0.3,
				}}
				whileTap={whileTap}
				onAnimationComplete={() => {
					onFinished()
				}}
			>
				<StickerResizingContainer
					scale={scale}
					rotation={rotation}
				>
					<StickerImage
						src={currentUrl}
						alt=""
					/>
				</StickerResizingContainer>
			</StickerPositioningContainer>
		)
	case 'slideLeft':
		return (
			<StickerPositioningContainer
				left={left}
				top={top}
				initial={{
					translateX: '100%',
				}}
				animate={{
					translateX,
				}}
				transition={{
					delay: animation?.delay || 0,
					duration: animation?.duration || 0.3,
				}}
				whileTap={whileTap}
				onAnimationComplete={() => {
					onFinished()
				}}
			>
				<StickerResizingContainer
					scale={scale}
					rotation={rotation}
				>
					<StickerImage
						src={currentUrl}
						alt=""
					/>
				</StickerResizingContainer>
			</StickerPositioningContainer>
		)
	default:
		return (
			<StickerPositioningContainer
				left={left}
				top={top}
				initial={{
					translateX,
					translateY,
				}}
				animate={{
					translateX,
					translateY,
				}}
				transition={{
					delay: animation?.delay || 0,
					duration: animation?.duration || 0.3,
				}}
				whileTap={whileTap}
				onAnimationComplete={() => {
					onFinished()
				}}
			>
				<StickerResizingContainer
					scale={scale}
					rotation={rotation}
				>
					<StickerImage
						src={currentUrl}
						alt=""
					/>
				</StickerResizingContainer>
			</StickerPositioningContainer>
		)
	}
}

export default StickerComponent
