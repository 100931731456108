import firebase from 'gatsby-plugin-firebase'
import { useEffect, useState } from 'react'
// import { unionBy } from 'lodash'

export interface HookOutput {
	actions: Array<IAction>

}

export interface IAction {
	label: string
	id: string
}

const useStickieActions = (stickieId: string, uid:string | undefined): HookOutput => {
	const [actions, setActions] = useState<Array<IAction>>(Array<IAction>())
	const [privateActions, setPrivateActions] = useState<Array<IAction>>(Array<IAction>())
	const [publicActions, setPublicActions] = useState<Array<IAction>>(Array<IAction>())

	// useEffect(() => {
	// const merged = unionBy(privateActions, publicActions, 'id')
	// setActions(merged)
	// }, [publicActions, privateActions, setActions])

	useEffect(() => {
		// console.log(publicActions)
	}, [publicActions])

	useEffect(() => {
		setActions(privateActions)
	}, [privateActions, setActions])

	useEffect(() => {
		const ref = firebase.firestore()
			.collection('stickerStats')
			.doc(stickieId)
			.collection('public')
			.doc('actions')
		const unsubscribe = ref.onSnapshot((doc) => {
			const { value: newActions } = doc.data() || { value: Array<IAction>() }
			// console.log(`New Public Actions=${JSON.stringify(newActions)}`)
			setPublicActions(newActions)
		})
		return (() => {
			unsubscribe()
		})
	}, [stickieId])

	useEffect(() => {
		if (uid) {
			const ref = firebase.firestore()
				.collection('stickerStats')
				.doc(stickieId)
				.collection('users')
				.doc(uid)
				.collection('state')
				.doc('actions')
			const unsubscribe = ref.onSnapshot((doc) => {
				const { value: newActions } = doc.data() || { value: Array<IAction>() }
				// console.log(`New Private Actions=${JSON.stringify(newActions)}`)
				setPrivateActions(newActions)
			})

			return (() => {
				unsubscribe()
			})
		}
		return () => {}
	}, [stickieId, uid])

	return {
		actions,

	}
}

export default useStickieActions
