import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { device, desktopDemoDevice } from '../../../utils/device'

export const Container = styled.div`
  /* position: absolute; */
	/* display: flex; */

	position: fixed;
	bottom: 64px;
	width: 100%;
	z-index: 290000;



  /* width: 100vw; */
  height: 56px;
  /* flex-direction: column; */
	/* border: 1px solid yellow; */

	@media ${device.isTabletOrLarger} { 
		width: ${desktopDemoDevice.width}px;
	}
`

export const ActionsContainer = styled.div`

  display: flex;


	justify-content: center;
  align-items: center;

  gap: 20px;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  /* box-shadow: 0 3px 10px 0 rgba(#000, 0.1); */
  -webkit-overflow-scrolling: touch;
  /* scroll-padding: 0px 5px 0px 5px; */
  /* padding: 0px 5px 0px 5px; */
  /* margin: 3rem auto 0; */
  
	::-webkit-scrollbar {
		display: none;
	}



	max-width: 100vw;
	height: auto;
	/* width: 100vw; */


	/* border:1px solid red; */

	@media ${device.isTabletOrLarger} { 
		max-width: ${desktopDemoDevice.width}px;
	}
`

export const ActionsButtonContainer = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 150px;
  /* width: 30px; */
  height: 50px;
  scroll-snap-align: start;
  scroll-snap-stop: normal;
	/* border:1px solid green; */
`

// .message-bubble-container.us {
//   justify-content: flex-end;
// }

export const ActionButton = styled.button<{disabled:boolean}>`
	text-align: center;
	background-color: var(--white1);
  margin: 1em 1em 1em 1em;
	padding: 6px 18px 6px 18px;
  border-radius: 18px;
  border: 0.25px solid var(--gray5);
  height: 36px;
  min-width: 100px;
  color: var(--brandColor);
	font-family: var(--slideshowFontFamily);
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.26px;
	line-height: 21px;
	white-space: nowrap;
	${(props) => props.disabled && css`
     opacity 0.2;
  `}
`
