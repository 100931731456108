import * as React from 'react'
import { IMessage } from '../../../../hooks/useStickieMessages'
import * as Styles from '../styles'

interface Props {
  // id: IMessage['id']
  content: IMessage['content']
	// author: IMessage['author']
	us: boolean
  // removeMessage: (key: string) => void
}

const MessageBubble:React.FC<Props> = (props) => {
	const {
		content, us,
	} = props

	return (
		<Styles.MessageBubble us={us}>
			{content}
		</Styles.MessageBubble>
	)
}

export default MessageBubble
