import * as React from 'react'
import { AnimatePresence } from 'framer-motion'
import { IMessage } from '../../../../hooks/useStickieMessages'
import MessageBubble from '../MessageBubble'
import * as Styles from '../styles'

interface Props {
  messages: IMessage[]
  // removeMessage: (key: string) => void
}

const MessageList:React.FC<Props> = (props) => {
	const { messages } = props

	return (
		// <Styles.MessagesContainer>
		<AnimatePresence initial={false}>
			{

				messages.length > 0 && messages.map((message) => (
					<Styles.MessagesBubbleContainer
						key={message.id}
						// positionTransition
						// className={`message-bubble-container ${message.author}`}
						initial={{ opacity: 0, y: 100 }}
						animate={{ opacity: 1, y: 0 }}
						exit={{ opacity: 0, transition: { duration: 0.15 } }}
						us={message.uid === 'me'}
					>
						<MessageBubble
							key={message.id}
							{...message}
							us={message.uid === 'me'}
						/>
					</Styles.MessagesBubbleContainer>
				))[messages.length - 1]

			}
		</AnimatePresence>
		// </Styles.MessagesContainer>
	)
}
export default MessageList
