import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { device, desktopDemoDevice } from '../../../utils/device'

export const Container = styled.div`
  position: absolute;
	display: flex;
  width: 100vw;
  height: 100vw;
  flex-direction: column;


	 justify-content: flex-end;
  overflow: hidden;


	z-index: 2000000;

	@media ${device.isTabletOrLarger} { 
		width: ${desktopDemoDevice.width}px;
		height: ${desktopDemoDevice.width}px;
	}
`
/*
export const MessagesContainer = styled.div`
  flex: 1 0 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
  position: relative;
`
*/

export const MessagesBubbleContainer = styled(motion.div)<{us:boolean}>`
  margin: 10px 20px 10px 20px;
  display: flex;
  flex-direction: row;

	${(props) => props.us && css`
		justify-content: flex-end;
  `}

/* 
	opacity: 1;
	
	&:nth-last-child(1n+3) {
		border: 1px solid red;
		opacity: 0.1;
	}

	&:nth-last-child(2n+3) {
		border: 1px solid yellow;
		opacity: 0.1;
	} */
`

// .message-bubble-container.us {
//   justify-content: flex-end;
// }

export const MessageBubble = styled.div<{us:boolean}>`
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.7) 0%, rgba(238, 238, 238, 0.7) 100%);
  padding: 12px 20px 12px 20px;
  border-radius: 20px 20px 3px 20px;
  font-size: 15px;
  color: var(--gray1);
	line-height: 23px;
  max-width: 80vw;

	${(props) => props.us && css`
		color: white;
		border-radius: 20px 20px 20px 3px;
		background: linear-gradient(180deg, rgba(255, 0, 140, 0.7) 0%, rgba(211, 9, 225,0.7)  100%);
  `}

	@media ${device.isTabletOrLarger} { 
		max-width: ${desktopDemoDevice.width * 0.8}px;
	}
`

// .message-bubble-container.us .message-bubble {
//   color: white;
//   border-radius: 20px 20px 20px 3px;
//   background: linear-gradient(180deg, #ff008c 0%, rgb(211, 9, 225) 100%);
// }

export const WaitingForReplyGif = styled.img`
	object-fit: contain;
	position: absolute;
	bottom: 0px;
	left: 20px;
	width: 30px;
	height: 30px;
	z-index: 3000000;
	/* border:1px solid green; */

`
