import styled, { css } from 'styled-components'
import { device, desktopDemoDevice } from '../../../utils/device'

export const Container = styled.div`
  position: fixed;
	bottom: 0px;
	width: 100%;
	z-index: 290000;
	display: flex;
  flex-direction: row;
  justify-content: center;
	align-items: center;

	border-top-style: solid;
	border-image: linear-gradient(to bottom, rgba(214, 219, 225, 0.5) 1%, rgba(196, 196, 196, 0) 100%) 
                100% 0 0 0/2px 0 2px 0 stretch;

	box-sizing: border-box;
	height: 64px;
	background-color: var(--white1);

	/* @media ${device.isTabletOrLarger} { 
		width: ${desktopDemoDevice.width}px;
		
	} */
`

export const Core = styled.div`
	width: 100%;
	display: flex;
  flex-direction: row;
  justify-content:space-between;
	font-size: 14px;
`

export const Data = styled.div`
	display: flex;
  flex-direction: row;
  justify-content:start;
	/* border:1px dotted red; */
	margin: 0px 0px 0px 8px;
	align-content: center;
	align-items: center;
`

// export const ViewDataIcon = styled.div`
//  width:24px;
//  height: 24px;
//  margin: 0;
//  /* border:1px solid black; */
// `
// export const AgoData = styled.div`
//  width:150px;
//  line-height: 16px;
//  color: var(--gray2);
//  /* border:1px solid black; */
// `

// export const ViewData = styled.div`
//  /* width:30px; */
//  margin: 0 0 0 3px;
//  line-height: 16px;
//  /* border:1px solid black; */
// `

// export const Dot = styled.div`
//  width:3px;
//  height: 3px;
//  border-radius: 50%;
//  margin: 0px 4px 0px 4px;
//  background-color: var(--gray2);
// `

export const Actions = styled.div`
	display: flex;
  flex-direction: row;
	justify-content: flex-end;
	align-items: center;
	width: 200px;
	height: 40px;
	/* border:1px dotted red; */
	margin: 0px 8px 0px 0px;
`

export const RootButton = styled.button<{disabled:boolean}>`
	cursor: pointer;
	height: 48px;
	width: 48px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-sizing: border-box;
	background-color: transparent;
	-webkit-tap-highlight-color: rgba(0,0,0,0);
	${(props) => props.disabled && css`
     opacity 0.2;
  `}
	
`

export const BidButton = styled.button`
	border: none;
	
	width: auto;
	height: 24px;
	
	font-size: 14px;
	font-weight: 500;
	background-color: #C0F2E6;
	/* min-width: 80px; */
	display: flex;
	align-items: center;
	padding-right: 16px;

	color: #018C6B;
 	border:1px solid #C0F2E6;
 	border-radius: 15px;
	
	
	flex-direction: row;
	
	justify-content: center;
	margin: 0px 0px 0px 10px;


`
/*
export const ButtonText = styled.div`
	height: 24px;
	width: 100px;
	padding:0px;

	font-size: 16px;
	font-weight: 500;

	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
`
*/

export const BidButtonText = styled.div`
	height: 24px;
	width: 80px;
	padding:0px;
	/* font-size: 12px; */
	/* font-weight: 400; */
	box-sizing: border-box;
	/* margin: 4px 0px 2px 0px; */
	border-left:1px solid #018c6c6c;
	display: flex;
	-webkit-align-items: center;
	align-items: center;
	-webkit-justify-content: center;
	justify-content: center;
`

export const SVGIcon = styled.div`
	/* margin:-1px 0px 0px -5px; */
	height: 36px;
	width: 36px;
	/* border:1px solid red; */
	display: flex;
	align-items: center;
	justify-content: center;
`

export const ItemValue = styled.div`
 	color: #018C6B;
	min-width: 57px;
	height: 24px;
	background-color: #C0F2E6;
	display:flex;
	align-items: center;
	justify-content: center;
	/* font-size: 12px; */
	/* font-weight: 400; */
`
