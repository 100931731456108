import React, { useContext } from 'react'
import * as Styles from './styles'
import SendDark from '../../../images/svg/send_black.svg'
import { MixpanelContext, AppUIEvent, Locations } from '../../../common/tracking'
import { siteData } from '../../../common/siteData'

interface ComponentProps {
	onShareHandler:Function
	onBidHandler:Function
	shareEnabled: boolean
	itemValue?: number
}

const Footer:React.FC<ComponentProps> = (props) => {
	const {
		itemValue = 0, onShareHandler, onBidHandler, shareEnabled,
	} = props
	const mixpanel = useContext(MixpanelContext)

	return (
		<Styles.Container>
			<Styles.Core>
				<Styles.Data>
					{
						itemValue === 0 ? null
							: (
								<Styles.BidButton
									disabled={false}
									onClick={() => {
										mixpanel.track(AppUIEvent.navigationBid, {
											location: Locations.stickieFooter,
											cta: siteData.bidButtonText,
											currentValue: itemValue.toFixed(2),
										})
										onBidHandler()
									}}
								>

									<Styles.ItemValue>{`$${itemValue.toFixed(2)}`}</Styles.ItemValue>
									<Styles.BidButtonText>
										{siteData.bidButtonText}
									</Styles.BidButtonText>

								</Styles.BidButton>
							)
					}

				</Styles.Data>
				<Styles.Actions>

					<Styles.RootButton
						disabled={!shareEnabled}
						onClick={() => {
							mixpanel.track(AppUIEvent.navigationShare, {
								location: Locations.stickieFooter,
								cta: siteData.shareButtonText,
							})
							onShareHandler()
						}}

					>
						<Styles.SVGIcon><SendDark width={56} height={56} /></Styles.SVGIcon>
						{/* <Styles.ButtonText>{siteData.shareButtonText}</Styles.ButtonText> */}

					</Styles.RootButton>

				</Styles.Actions>
			</Styles.Core>
			{/* <Styles.StyledLink to="/">
				<Styles.StyledLogo2 src={logoStickies} alt="" />
			</Styles.StyledLink> */}
		</Styles.Container>
	)
}

Footer.defaultProps = {
	// onBidHandler: () => {},
	itemValue: 0,
	// showShareButton: false,
	// showBidButton: false,
	// showItemValue: false,
}

const areEqual = (prevProps:ComponentProps, nextProps:ComponentProps):boolean => (prevProps.itemValue === nextProps.itemValue && prevProps.shareEnabled === nextProps.shareEnabled
)
export default React.memo(Footer, areEqual)
