// import React, { useImperativeHandle, useEffect, useState } from 'react'
import React from 'react'
// import { motion } from 'framer-motion'
// import { sample } from 'lodash'
// import { differenceBy } from 'lodash'
import MessageList from './MessageList'
import { IMessage } from '../../../hooks/useStickieMessages'
import * as Styles from './styles'
// import usePrevious from '../../../hooks/usePrevious'
import waitingForReplyGif from '../../../images/dots.gif'

interface ComponentProps {
	// passedInRef: any
	allMessages: Array<IMessage>
	waitingForReply: boolean
}

const Example:React.FC<ComponentProps> = (props) => {
	const { /* passedInRef, */ allMessages, waitingForReply } = props
	// const { allMessages } = useStickieMessages(stickieId)
	// const [messages, setMessages] = useState<Array<IMessage>>([])
	// const [messages, setMessages] = useState<Array<IMessage>>(allMessages)
	// const previousRecentMessages = usePrevious(allMessages)

	// console.log(`recentMessages=${JSON.stringify(recentMessages)}`)
	/*
	const removeMessage = (key: string):void => {
		const newMessages = [...messages]
		newMessages.splice(newMessages.findIndex(({ id }) => id === key), 1)
		setMessages(newMessages)
	}
	*/

	/*
	useEffect(() => {
		if (previousRecentMessages !== allMessages) {
			const diffA = differenceBy(allMessages, messages, 'id')
			setMessages([...messages, ...diffA])
			// console.log(diffA)
		}
	}, [messages, previousRecentMessages, allMessages])
*/
	/*
	useImperativeHandle(passedInRef, () => ({
		sendMessage: (message: IMessage):void => setMessages([...messages, message]),
	}))
	*/

	return (
		<Styles.Container>
			{/* <MessageList messages={messages} /> */}
			<MessageList messages={allMessages} />
			{
				waitingForReply && (
					<Styles.WaitingForReplyGif src={waitingForReplyGif} />
				)
			}
		</Styles.Container>
	)
}

const areEqual = (prevProps:ComponentProps, nextProps:ComponentProps):boolean => (prevProps.allMessages === nextProps.allMessages && prevProps.waitingForReply === nextProps.waitingForReply)
export default React.memo(Example, areEqual)
