import React, { useContext } from 'react'

// import uniqid from 'uniqid'
import * as Styles from './styles'
import useStickieActions, { IAction } from '../../../hooks/useStickieActions'
// import { IMessage } from '../../../hooks/useStickieMessages'
import ActionList from './ActionList'
import { AuthContext, AuthContextProps } from '../../User/AuthProvider'

interface ComponentProps {
	stickieId: string
	// sendMessage: Function
	executeCloudCall: Function
	disabled: boolean
}

const Actions:React.FC<ComponentProps> = (props) => {
	const {
		stickieId, executeCloudCall, disabled,
	} = props
	const { user }:Partial<AuthContextProps> = useContext(AuthContext)
	const { uid } = user || {}
	const { actions } = useStickieActions(stickieId, uid)

	const doAction = (action:IAction):void => {
		const { /* label, */ id } = action

		/*
		sendMessage({
			message: {
				id: uniqid(),
				content: label,
				uid: 'me',
			} as IMessage,
		})
		*/

		executeCloudCall(id)
	}

	return (
		<Styles.Container>
			<ActionList actions={actions} doAction={doAction} disabled={disabled} />
		</Styles.Container>
	)
}

const areEqual = (prevProps:ComponentProps, nextProps:ComponentProps):boolean => (prevProps.stickieId === nextProps.stickieId && prevProps.disabled === nextProps.disabled)
export default React.memo(Actions, areEqual)
