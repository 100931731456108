import * as React from 'react'
import { AnimatePresence } from 'framer-motion'
import { IAction } from '../../../../hooks/useStickieActions'
import ActionButton from '../ActionButton'
import * as Styles from '../styles'

interface Props {
  actions: IAction[]
	doAction: (action: IAction) => void
	disabled: boolean
}

const ActionList:React.FC<Props> = (props) => {
	const { actions, doAction, disabled } = props

	return (
		<Styles.ActionsContainer>
			<AnimatePresence initial={false}>
				{actions.map((action) => (
					<Styles.ActionsButtonContainer
						key={action.id}
						positionTransition
						initial={{ opacity: 0, x: -100 }}
						animate={{ opacity: 1, x: 0 }}
						exit={{ opacity: 0, transition: { duration: 0.15 } }}
					>
						<ActionButton
							key={action.id}
							action={action}
							doAction={doAction}
							disabled={disabled}
						/>
					</Styles.ActionsButtonContainer>
				))}
			</AnimatePresence>
		</Styles.ActionsContainer>
	)
}
export default ActionList
